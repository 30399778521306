import { Ziggy } from './../ziggy';
import route from 'ziggy';

export default {
    install: (app, options) => {
        const currentZiggy = new myZiggy(options);

        app.provide('route', (name, params, absolute, config = Ziggy) =>
            route(currentZiggy.translatedRouteName(name), params, absolute, config)
        );

        app.provide('setZiggyLocale', (locale) => currentZiggy.setLocale(locale));
    },
};

class myZiggy {
    constructor(options = {}) {
        this.locale = options.locale;
    }

    setLocale(locale) {
        this.locale = locale;
    }

    translatedRouteName(name) {
        return this.needTranslation(name) ? this.locale + '.' + name : name;
    }

    needTranslation(name) {
        if (typeof this.locale == 'undefined') {
            return false;
        }

        if (typeof name != 'string') {
            return false;
        }

        if (name.startsWith('api.')) {
            return false;
        }

        return true;
    }
}
