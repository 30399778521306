import './bootstrap';

// Vue
import { createSSRApp, h } from 'vue';

// Inertia
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

// Pinia
import { createPinia } from 'pinia';

// Flare
import { flare } from '@flareapp/flare-client';
import { flareVue } from '@flareapp/flare-vue';

if (import.meta.env.NODE_ENV === 'production') {
    flare.light(import.meta.env.VITE_FLARE_KEY);
}

// VCalendar
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';

// Formkit
import { defaultConfig, plugin as FKp } from '@formkit/vue';
import { nl } from '@formkit/i18n';
import { generateClasses } from '@formkit/themes';
import formkitTheme from '@/formkitTheme';
import { createAutoAnimatePlugin } from '@formkit/addons';

// vue-gtm
import { createGtm } from '@gtm-support/vue-gtm';

// Plugins
import ziggy from './plugins/ziggy';
import dayjs from './plugins/dayjs';

createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const currentLocale = props.initialPage.props.locale;
        createSSRApp({ render: () => h(App, props) })
            .use(plugin)
            .use(flareVue)
            .use(createPinia())
            .use(VCalendar)
            .use(ziggy, { locale: currentLocale })
            .use(dayjs, { locale: currentLocale })
            .use(
                FKp,
                defaultConfig({
                    config: {
                        classes: generateClasses(formkitTheme),
                    },
                    locales: { nl },
                    locale: 'nl',
                    plugins: [createAutoAnimatePlugin()],
                })
            )
            .use(
                createGtm({
                    id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
                    enabled: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ENABLED == 'true',
                })
            )
            .mount(el);
    },
});
